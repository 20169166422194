document.addEventListener("DOMContentLoaded", function() {
    const lottieAnimations = document.querySelectorAll('.lottie-animation');

    lottieAnimations.forEach(animation => {
        const file = animation.getAttribute('data-lottie-file');
        let autoplayd = animation.getAttribute('data-autoplay');
        let loopd = animation.getAttribute('data-loop');
        const speed = parseFloat(animation.getAttribute('data-speed'));
        
        if ( autoplayd === '1' ) {
            autoplayd = true;
        }
        else {
            autoplayd = false;
        }
        if (loopd === '1') {
            loopd = true;
        }
        else {
            loopd = false;
        }

        const anim = lottie.loadAnimation({
            container: animation, 
            renderer: 'svg',
            loop: loopd,
            autoplay: autoplayd,
            path: file 
        });

        anim.setSpeed(speed);
    });
});
